<template>
  <q-card
    bordered
    class="q-pa-sm shadow-1"
    :class="isActiveBg"
    style="max-width: 90vw"
    :style="!editing ? 'width: 340px;' : ''"
  >
    <q-card-section
      class="
        row
        items-center
        q-pb-none
        text-green-vito
        q-gutter-x-md
        justify-between
      "
    >
      <div class="text-h6 text-capitalize">{{ title }}</div>
      <div class="q-gutter-x-sm row">
        <div v-if="action == 'display' || action == 'edit'">
          <q-btn
            icon="delete_forever"
            square
            round
            dense
            v-if="form.deletable"
            class="text-red-vito"
            @click="handleDeleteButton"
          />
          <q-tooltip class="bg-green-vito text-no-wrap">
            {{ $t("tooltip.deleteProduct") }}
          </q-tooltip>
        </div>
        <div v-if="action == 'display'">
          <q-btn
            icon="edit"
            square
            round
            dense
            @click="$emit('edit', product.id)"
          />
          <q-tooltip class="bg-green-vito text-no-wrap">
            {{ $t("tooltip.editProduct") }}
          </q-tooltip>
        </div>
        <div v-if="editing">
          <q-btn icon="settings" square round dense @click="$emit('cancel')" />
          <q-tooltip class="bg-green-vito text-no-wrap">
            {{ $t("tooltip.listProduct") }}
          </q-tooltip>
        </div>
      </div>
    </q-card-section>

    <q-card-section class="row justify-center">
      <q-img
        v-if="action == 'display'"
        class="col"
        :src="productImage"
        absolute-right
        style="min-width: 180px; max-width: 180px"
      />
      <div
        v-if="editing"
        class="q-mt-md"
        style="min-height: 320px; min-width: 200px; max-width: 200px"
      >
        <q-img
          class="col"
          :src="productImage"
          absolute-right
          style="min-width: 200px; max-width: 200px"
        />
        <file-pond
          name="file"
          ref="pond"
          :label-idle="$t('label.dragImage')"
          allow-multiple="false"
          allow-remove="true"
          max-files="1"
          accepted-file-types="image/*"
          :files="files"
          @init="handleFilePondInit"
          @addfile="addFile"
        />
      </div>

      <q-card-section
        class="col"
        v-if="action == 'display'"
        style="min-width: 320px"
      >
        <div class="text-h6 text-green-vito text-center">
          {{ product.name }}
        </div>
      </q-card-section>

      <q-card-section class="col" v-if="editing" style="min-width: 320px">
        <q-form class="q-gutter-y-md">
          <q-input
            ref="name"
            square
            filled
            clearable
            v-model="form.name"
            :label="$t('label.name')"
            :hint="$t('hint.minchar', { chars: 2 })"
            lazy-rules
            :rules="[this.required, this.short]"
          >
          </q-input>
          <q-select
            ref="type"
            :options="typeOptions"
            square
            filled
            clearable
            v-model="form.type"
            :label="$t('label.type')"
            :hint="$t('hint.minchar', { chars: 2 })"
            lazy-rules
            :rules="[this.required, this.short]"
          >
          </q-select>
          <q-input
            ref="weight"
            square
            filled
            clearable
            type="number"
            v-model.number="form.weight"
            :label="$t('label.weight')"
            lazy-rules
            :rules="[this.required, this.isNumber]"
            :hint="$t('hint.number') + ', ' + $t('hint.required')"
          />
          <q-input
            ref="codeSAP"
            square
            filled
            clearable
            type="string"
            v-model.number="form.codeSAP"
            :label="$t('label.codeSAP')"
            lazy-rules
            :rules="[this.required, this.isNumber]"
            :hint="$t('hint.codeSAP') + ', ' + $t('hint.required')"
          />
          <q-input
            ref="qrCodeUrl"
            square
            filled
            clearable
            type="string"
            v-model.number="form.qrCodeUrl"
            :label="$t('label.qrCodeUrl')"
            lazy-rules
            :rules="[this.required]"
            :hint="$t('hint.qrCodeUrl') + ', ' + $t('hint.required')"
          />
          <q-toggle
            ref="isClip"
            v-model="form.isClip"
            :label="form.isClip == true ? $t('clip') : $t('bottle')"
            color="green-vito"
          >
          </q-toggle>
          <q-input
            ref="position"
            square
            filled
            clearable
            type="number"
            v-model.number="form.position"
            :label="$t('label.position')"
            lazy-rules
            :rules="[this.required, this.isNumber]"
            :hint="$t('hint.number') + ', ' + $t('hint.required')"
          >
          </q-input>
          <q-input
            ref="image"
            square
            filled
            disable
            v-model="form.image"
            :label="$t('label.image')"
          ></q-input>
          <q-toggle
            ref="isActive"
            v-model="form.isActive"
            :label="form.isActive == true ? $t('active') : 'Inactif'"
            color="green-vito"
          >
          </q-toggle>
        </q-form>
      </q-card-section>
    </q-card-section>

    <q-card-section v-if="errorMessage">
      <div class="text-red-vito text-center">
        <strong>{{ errorMessage }}</strong>
      </div>
    </q-card-section>

    <q-card-actions class="q-px-md justify-end" v-if="editing">
      <q-btn
        unelevated
        size="md"
        class="text-green-vito"
        :label="$t('button.cancel')"
        @click="$emit('cancel')"
      />
      <q-btn
        unelevated
        size="md"
        class="bg-green-vito text-white"
        :label="
          (form.id ? $t('button.save') : $t('button.add')) + ' ' + $t('product')
        "
        @click="handleSave"
      />
    </q-card-actions>
  </q-card>
</template>

<script>
import ProductApi from "../../apis/Product";
import vueFilePond, { setOptions } from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
);
export default {
  props: ["product", "action"],
  components: {
    FilePond,
  },
  data: () => {
    return {
      files: [],
      form: {
        id: null,
        name: "",
        type: "",
        weight: 0,
        codeSAP: "",
        qrCodeUrl: "",
        isClip: false,
        isActive: null,
        deletable: true,
        position: 0,
      },
      errorMessage: null,
      typeOptions: ["Butane", "Propane"]
    };
  },
  async mounted() {
    try {
      this.form = JSON.parse(JSON.stringify(this.product));
    } catch (error) {
      console.error(error.message);
    }
  },
  methods: {
    handleFilePondInit: async function () {
      //console.log("FilePond has initialized", this.$refs.pond);
      setOptions({
        server: {
          url: process.env.VUE_APP_BASE_URL + "/product/upload",
          process: {
            method: "POST",
            withCredentials: false,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            timeout: 7000,
            onload: (response) => {
              //console.log("refresh");
              this.$refs.pond.removeFiles();
              //console.log(response);
              this.form.image = response;
              return response;
            },
          },
          revert: (uniqueFileId, load, error) => {
            //console.log(uniqueFileId);
            ProductApi.deleteImage(uniqueFileId);
            load();
            this.form.image = null;
            error("delete image failure");
          },
        },
      });
    },
    addFile(error, file) {
      if (error === null && file) {
        //console.log(file.filename);
        //console.log(this.$refs.pond);
      }
    },
    async handleSave() {
      this.errorMessage = null;
      ["name", "weight", "position"].forEach((f) => {
        if (!this.$refs[f].validate()) {
          this.errorMessage = this.$t("validation.verify");
          return;
        }
      });
      if (!this.errorMessage) {
        try {
          const payload = (({
            id,
            name,
            weight,
            codeSAP,
            qrCodeUrl,
            type,
            isClip,
            image,
            position,
            isActive,
          }) => ({
            id,
            name,
            weight,
            codeSAP,
            qrCodeUrl,
            type,
            isClip,
            image,
            position,
            isActive,
          }))(this.form);
          //console.log(payload);
          const saveResponse =
            payload.id == 0
              ? await ProductApi.create(payload)
              : await ProductApi.save(payload);
          this.$q.notify({
            type: "positive",
            message: this.$t(saveResponse.data.message),
          });
          //console.log(saveResponse.data);
          this.$emit("refreshAll");
        } catch (error) {
          const message = error.response
            ? error.response.data.message
            : error.message;
          this.errorMessage = message;
          this.$q.notify({
            type: "negative",
            message: message,
          });
        }
      }
    },
    handleDeleteButton() {
      this.$q
        .dialog({
          title: this.$t("title.confirmDelete"),
          message: this.$t("message.confirmDeleteProduct"),
          ok: {
            color: "red-vito",
            label: this.$t("button.deleteDefinitively"),
          },
          cancel: {
            label: this.$t("button.cancel"),
            color: "green-vito",
          },
          persistent: true,
        })
        .onOk(() => {
          this.deleteProduct();
        });
    },
    async deleteProduct() {
      try {
        const deleteResponse = await ProductApi.delete(this.form.id);
        this.$q.notify({
          type: "positive",
          message: this.$t(deleteResponse.data.message),
        });
        //console.log(saveResponse.data);
        this.$emit("refreshAll");
      } catch (error) {
        let message = error.response
          ? error.response.data.message
          : error.message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
        //console.error(message);
      }
    },
    required(val) {
      //console.log("val=", val, val.toString().length);
      return (
        (val && val.toString().length > 0) || this.$t("validation.required")
      );
    },
    short(val) {
      return (
        (val && val.length > 1) || this.$t("validation.minchar", { chars: 2 })
      );
    },
    isNumber(val) {
      return !isNaN(val) || this.$t("validation.number");
    },
  },
  computed: {
    title() {
      return `${this.$t("product")} #${this.product.id}`;
    },
    editing() {
      return this.action == "create" || this.action == "edit";
    },
    isActiveBg() {
      return this.form.isActive == false ? "bg-grey-3" : "";
    },
    productImage() {
      return (
        process.env.VUE_APP_BASE_URL + "/product/images/" + this.form.image
      );
    },
  },
};
</script>
