<template>
  <q-card
    flat
    class="q-pa-sm"
    style="max-width: 100vw"
    :style="isPage ? '' : 'width: 990px; '"
    :class="isPage ? 'full-width' : ''"
  >
    <q-card-section class="row q-pb-sm text-green-vito justify-between">
      <div class="text-h5">
        {{ $t("title.displayLocker") }} #{{ locker.id }}
      </div>
      <!-- <div class="row items-center">
        {{ $moment(locker.updated_at).format("DD/MM/YY HH:mm:ss") }}
        <q-tooltip class="bg-green-vito text-no-wrap">{{
          $t("label.lastUpdate")
        }}</q-tooltip>
      </div> -->
      <div v-if="!isPage">
        <q-btn icon="close" flat round dense v-close-popup />
      </div>
    </q-card-section>

    <q-card-section class="row justify-between">
      <div class="text-center col-12 col-sm-2">
        <div class="q-mr-sm"><locker :locker="locker" /></div>
      </div>
      <div class="col-12 col-sm-5">
        <q-markup-table flat>
          <tr class="no-padding-md">
            <td width="140">{{ $t("label.closed") }}</td>
            <td>
              <span>
                <q-chip
                  class="q-ma-none"
                  square
                  :text-color="statusColor('isClosed')"
                  :icon="statusIcon('isClosed')"
                >
                  {{ $t(locker.isClosed.toString()) }}
                </q-chip>
              </span>
            </td>
          </tr>
          <tr class="no-padding-md">
            <td>{{ $t("label.locked") }}</td>
            <td>
              <span>
                <q-chip
                  class="q-ma-none"
                  square
                  :text-color="statusColor('isLocked')"
                  :icon="statusIcon('isLocked')"
                >
                  {{ $t(locker.isLocked.toString()) }}
                </q-chip>
              </span>
            </td>
          </tr>
          <tr class="no-padding-md" v-if="!locker.isClip">
            <td>{{ $t("label.presence") }}</td>
            <td>
              <span>
                <q-chip
                  class="q-ma-none"
                  square
                  :text-color="statusColor('isEmpty')"
                  :icon="statusIcon('isEmpty')"
                >
                  {{ $t((!locker.isEmpty).toString()) }}
                </q-chip>
              </span>
            </td>
          </tr>
          <tr class="no-padding-md">
            <td>{{ $t("label.faulty") }}</td>
            <td>
              <span>
                <q-chip
                  class="q-ma-none"
                  square
                  :text-color="statusColor('isFaulty')"
                  :icon="statusIcon('isFaulty')"
                >
                  {{ $t(locker.isFaulty.toString()) }}
                </q-chip>

                <q-btn
                  v-if="locker.isFaulty"
                  class="q-ml-md"
                  dense
                  round
                  @click="confirmFaultReset = true"
                  icon="thumb_up"
                >
                  <q-tooltip class="bg-green-vito text-no-wrap">
                    {{ $t("tooltip.resetFaulty") }}
                  </q-tooltip></q-btn
                >
                <q-btn
                  v-else
                  class="q-ml-md"
                  dense
                  round
                  @click="confirmFaultSetup = true"
                  icon="thumb_down"
                >
                  <q-tooltip class="bg-green-vito text-no-wrap">
                    {{ $t("tooltip.setFaulty") }}
                  </q-tooltip>
                </q-btn>
                <q-btn
                  v-if="locker.faultCount"
                  class="q-ml-md"
                  dense
                  round
                  @click="confirmFaultCountReset = true"
                  :icon="statusIcon('faultCount')"
                >
                  <q-tooltip class="bg-green-vito text-no-wrap">
                    {{ $t("tooltip.resetFaultCount") }}
                  </q-tooltip>
                  <q-badge color="red-vito" floating>{{
                    locker.faultCount
                  }}</q-badge></q-btn
                >
              </span>
            </td>
          </tr>

          <tr class="no-padding-md">
            <td>{{ $t("label.status") }}</td>
            <td>
              <div class="row wrap q-gutter-sm">
                <span>
                  <q-chip
                    class="q-ma-none"
                    square
                    :text-color="statusColor('lockerStatus')"
                    :icon="statusIcon('lockerStatus')"
                  >
                    {{ statusValue(locker.lockerStatus) }}
                  </q-chip>
                </span>
                <span v-if="!locker.isActive">
                  <q-chip
                    class="q-ma-none"
                    square
                    color="grey-4"
                    floating
                    icon="highlight_off"
                    >{{ $t("label.disabled") }}</q-chip
                  >
                </span>
              </div>
            </td>
          </tr>
          <tr class="no-padding-md">
            <td>{{ $t("label.lastUpdate") }}</td>
            <td class="highlight-values">
              <span>{{
                $moment(locker.updated_at).format("DD/MM/YY HH:mm:ss")
              }}</span>
            </td>
          </tr>
        </q-markup-table>
      </div>
      <div class="col-12 col-sm-5" v-if="locker.article">
        <q-markup-table flat class="">
          <tr class="no-padding-md">
            <td width="140">{{ $t("label.product") }}</td>
            <td>
              <q-chip square class="q-ma-none">
                {{
                  `${locker.article.product.name} ${locker.article.product.weight} Kg`
                }}
              </q-chip>
              <q-btn
                v-if="!locker.article.isReserved"
                class="q-ml-md"
                dense
                round
                @click="confirmMoveOut = true"
                icon="logout"
              >
                <q-tooltip class="bg-green-vito text-no-wrap">
                  {{ $t("tooltip.moveOut") }}
                </q-tooltip>
              </q-btn>
            </td>
          </tr>
          <tr class="no-padding-md">
            <td>{{ $t("label.returnable") }}</td>
            <td>
              <q-chip square class="q-ma-none">
                {{ $t(locker.article.isReturnable.toString()) }}
              </q-chip>
              <q-chip
                clickable
                @click="$router.push('/order/' + locker.article.order.id)"
                square
                class="q-ma-none q-ml-md text-green-vito"
                v-if="locker.article.order && locker.article.isReturnable"
              >
                <span class="q-mr-sm">{{ $t("label.order") }}</span>
                <strong>
                  <u>{{ locker.article.order.id }}</u>
                </strong>
              </q-chip>
              <!-- <q-btn
                v-if="!locker.article.isReserved && !locker.article.order"
                class="q-ml-md"
                dense
                round
                @click="confirmToggleReturnable = true"
                icon="edit"
              >
                <q-tooltip class="bg-green-vito text-no-wrap">
                  {{ $t("tooltip.toggleReturnable") }}
                </q-tooltip>
              </q-btn> -->
            </td>
          </tr>
          <tr class="no-padding-md">
            <td>{{ $t("label.reserved") }}</td>
            <td>
              <q-chip square class="q-ma-none">
                {{ $t(locker.article.isReserved.toString()) }}
              </q-chip>
              <q-chip
                clickable
                @click="$router.push('/order/' + locker.article.order.id)"
                square
                class="q-ma-none q-ml-md text-green-vito"
                v-if="locker.article.isReserved"
              >
                <span class="q-mr-sm">{{ $t("label.order") }}</span>
                <strong>
                  <u>{{ locker.article.order.id }}</u>
                </strong>
              </q-chip>
            </td>
          </tr>
        </q-markup-table>
      </div>
      <div class="col-12 col-sm-5" v-else>
        <q-item-label class="text-italic text-green-vito q-py-sm">
          {{ $t("label.selectProductAndMoveIn") }}
        </q-item-label>
        <div class="row no-wrap">
          <div class="column">
            <product-selection
              v-if="newProduct"
              :options="products"
              :selectedProduct="newProduct"
              type="select"
              @productSelectionChange="productSelectionChange"
            />
            <q-toggle
              v-model="isReturnableProduct"
              color="green-vito"
              :label="$t('button.returnable')"
              class="text-green-vito"
            />
          </div>
          <div>
            <q-btn
              class="q-ml-md"
              dense
              round
              @click="confirmMoveIn = true"
              icon="login"
            >
              <q-tooltip class="bg-green-vito text-no-wrap">
                {{ $t("tooltip.moveIn") }}
              </q-tooltip>
            </q-btn>
          </div>
        </div>
      </div>
    </q-card-section>

    <q-card-section>
      <locker-orders :locker="locker" :key="refreshCounter" />
    </q-card-section>

    <q-card-section>
      <locker-logs :locker="locker" :key="refreshCounter" />
    </q-card-section>
  </q-card>

  <q-dialog v-model="confirmFaultReset">
    <q-card>
      <q-card-section class="row items-center">
        <q-avatar icon="thumb_up" color="green-vito" text-color="white" />
        <span class="q-ml-sm">{{ $t("title.lockerActionResetFaulty") }}</span>
      </q-card-section>
      <q-card-section>
        <q-toggle
          v-model="isDisabled"
          val="other"
          color="red-vito"
          icon="close"
          size="xl"
          :label="$t('label.disableLocker')"
        />
      </q-card-section>
      <q-card-actions align="right">
        <q-btn
          flat
          :label="$t('button.cancel')"
          unelevated
          size="md"
          class="text-green-vito"
          v-close-popup
        />
        <q-btn
          flat
          :label="$t('button.reset')"
          unelevated
          size="md"
          class="bg-green-vito text-white"
          @click="resetFaulty"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog v-model="confirmFaultCountReset">
    <q-card>
      <q-card-section class="row items-center no-wrap">
        <q-avatar icon="restart_alt" color="green-vito" text-color="white" />
        <span class="q-ml-sm">{{
          $t("title.lockerActionResetFaultCount")
        }}</span>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          flat
          :label="$t('button.cancel')"
          unelevated
          size="md"
          class="text-green-vito"
          v-close-popup
        />
        <q-btn
          flat
          :label="$t('button.reset')"
          unelevated
          size="md"
          class="bg-green-vito text-white"
          @click="resetFaultCount"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog v-model="confirmFaultSetup">
    <q-card>
      <q-card-section class="row items-center">
        <q-avatar icon="thumb_down" color="red-vito" text-color="white" />
        <span class="q-ml-sm">{{ $t("title.lockerActionSetFaulty") }}</span>
      </q-card-section>

      <q-card-section class="column">
        <q-toggle
          v-model="faultSelection"
          val="door"
          color="red-vito"
          icon="meeting_room"
          size="xl"
          :label="$t('label.doorFault')"
          @update:model-value="validateStatus('door')"
        />

        <q-toggle
          v-model="faultSelection"
          val="lock"
          color="red-vito"
          icon="lock_open"
          size="xl"
          :label="$t('label.lockFault')"
          @update:model-value="validateStatus('lock')"
        />

        <q-toggle
          v-model="faultSelection"
          val="presence"
          color="red-vito"
          icon="sensors"
          size="xl"
          :label="$t('label.presenceFault')"
          @update:model-value="validateStatus('presence')"
        />
        <q-toggle
          v-model="faultSelection"
          val="mismatch"
          color="red-vito"
          icon="compare"
          size="xl"
          :label="$t('label.mismatchFault')"
          @update:model-value="validateStatus('mismatch')"
        />
        <q-toggle
          v-model="faultSelection"
          val="other"
          color="red-vito"
          icon="question_mark"
          size="xl"
          :label="$t('label.otherFault')"
          @update:model-value="validateStatus('other')"
        />
        <q-separator />
        <q-toggle
          v-model="isDisabled"
          val="other"
          color="red-vito"
          icon="close"
          size="xl"
          :label="$t('label.disableLocker')"
        />
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          flat
          :label="$t('button.cancel')"
          unelevated
          size="md"
          class="text-green-vito"
          v-close-popup
        />
        <q-btn
          flat
          :label="$t('button.ok')"
          unelevated
          size="md"
          class="bg-green-vito text-white"
          :disable="
            faultSelection.length == 0 && locker.isActive !== isDisabled
          "
          @click="setFaulty"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog v-model="confirmMoveOut">
    <q-card>
      <q-card-section class="row items-center no-wrap">
        <q-avatar icon="logout" color="green-vito" text-color="white" />
        <span class="q-ml-sm">{{ $t("title.confirmMoveOut") }}</span>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          flat
          :label="$t('button.cancel')"
          unelevated
          size="md"
          class="text-green-vito"
          v-close-popup
        />
        <q-btn
          flat
          :label="$t('button.ok')"
          unelevated
          size="md"
          class="bg-green-vito text-white"
          @click="handleMoveOut"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog v-model="confirmMoveIn">
    <q-card>
      <q-card-section class="row items-center no-wrap">
        <q-avatar icon="login" color="green-vito" text-color="white" />
        <span class="q-ml-sm">{{ $t("title.confirmMoveIn") }}</span>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          flat
          :label="$t('button.cancel')"
          unelevated
          size="md"
          class="text-green-vito"
          v-close-popup
        />
        <q-btn
          flat
          :label="$t('button.ok')"
          unelevated
          size="md"
          class="bg-green-vito text-white"
          @click="handleMoveIn"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import LockerLogs from "./LockerLogs.vue";
import LockerOrders from "./LockerOrders.vue";
import Locker from "./Locker.vue";
import ProductSelection from "../product/ProductSelection.vue";
import ProductApi from "../../apis/Product";
import DisplayApi from "../../apis/Display";
import { LockerStatus, LockerLogStatus } from "./locker.enum";

export default {
  props: ["locker", "store", "display", "isPage"],
  components: { LockerLogs, Locker, ProductSelection, LockerOrders },
  emits: ["refresh", "cancel"],

  data: () => {
    return {
      showComplete: false,
      errorMessage: null,
      refreshCounter: 0,
      confirmFaultReset: false,
      confirmFaultSetup: false,
      isReservedWithNoOrder: false,
      newProduct: null,
      isReturnableProduct: false,
      products: [],
      confirmMoveIn: false,
      confirmMoveOut: false,
      faultSelection: [],
      faultStatusValue: 0,
      confirmFaultCountReset: false,
      isDisabled: false,
      isClip: false,
    };
  },
  async mounted() {
    console.log("locker", this.locker);
    this.isReservedWithNoOrder =
      this.locker.article &&
      this.locker.article.isReserved &&
      this.locker.article.order == null;
    this.isDisabled = !this.locker.isActive;
    this.isClip= this.locker.isClip;
    await this.getProducts();
  },
  updated() {
    //console.log(this.newProduct);
  },
  methods: {
    validateStatus(value) {
      const removeOthers = () => {
        ["mismatch", "other"].forEach((status) => {
          let statusIndex = this.faultSelection.findIndex((el) => el == status);
          if (statusIndex > -1) {
            this.faultSelection.splice(statusIndex, 1);
          }
        });
      };
      if (["lock", "door", "presence"].includes(value)) {
        removeOthers();
      } else {
        this.faultSelection = this.faultSelection.find((el) => el == value)
          ? [value]
          : [];
      }
      this.faultStatusValue = 0;
      ["lock", "door", "presence", "mismatch", "other"].forEach((fault) => {
        let isSet = this.faultSelection.includes(fault);
        if (isSet) {
          switch (fault) {
            case "door":
              this.faultStatusValue = this.faultStatusValue | 1;
              break;
            case "lock":
              this.faultStatusValue = this.faultStatusValue | 2;
              break;

            case "presence":
              this.faultStatusValue = this.faultStatusValue | 4;
              break;

            case "mismatch":
              this.faultStatusValue = 8;
              break;

            case "other":
              this.faultStatusValue = 9;
              break;
          }
        }
      });
    },

    async setFaulty() {
      try {
        const resetResponse = await DisplayApi.setFaulty(this.display.id, {
          lockerId: this.locker.id,
          error: this.faultStatusValue,
          isActive: !this.isDisabled,
        });
        this.$emit("refresh");
        this.confirmFaultSetup = false;
        this.$q.notify({
          type: "positive",
          message: this.$t(resetResponse.data.message),
        });
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },

    async handleMoveIn() {
      this.errorMessage = null;
      try {
        const addItemResponse = await DisplayApi.moveIn(this.display.id, {
          lockerId: this.locker.id,
          productId: this.newProduct.id,
          returnable: this.isReturnableProduct,
        });

        this.$emit("refresh");
        this.$q.notify({
          type: "positive",
          message: this.$t(addItemResponse.data.message),
        });
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },

    async handleMoveOut() {
      this.errorMessage = null;
      try {
        const removeItemResponse = await DisplayApi.moveOut(this.display.id, {
          lockerId: this.locker.id,
          articleId: this.locker.article.id,
        });

        this.$emit("refresh");
        this.$q.notify({
          type: "positive",
          message: this.$t(removeItemResponse.data.message),
        });
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },

    async resetFaulty() {
      try {
        const resetResponse = await DisplayApi.resetFaulty(this.display.id, {
          lockerId: this.locker.id,
          isActive: !this.isDisabled,
        });

        this.$emit("refresh");
        this.$q.notify({
          type: "positive",
          message: this.$t(resetResponse.data.message),
        });
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },

    async resetFaultCount() {
      try {
        const resetResponse = await DisplayApi.resetFaultCount(
          this.display.id,
          {
            lockerId: this.locker.id,
          }
        );

        this.$emit("refresh");
        this.$q.notify({
          type: "positive",
          message: this.$t(resetResponse.data.message),
        });
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },

    async resetReserved() {
      try {
        const resetResponse = await DisplayApi.resetReserved(this.display.id, {
          lockerId: this.locker.id,
        });

        this.$emit("refresh");
        this.$q.notify({
          type: "positive",
          message: this.$t(resetResponse.data.message),
        });
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },

    async getProducts() {
      try {
        const productsResponse = await ProductApi.findActive();
        this.products = productsResponse.data;
        this.newProduct = productsResponse.data.length
          ? productsResponse.data[0]
          : null;
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },

    productSelectionChange(item) {
      const selectedItem = item.selectedItem;

      // console.log("parsed", selectedItem);
      // console.log("change seleted from", this.newProduct.id);
      //console.log("change seleted to ", selectedItem.id);
      this.newProduct = selectedItem;
    },

    refresh() {
      this.showComplete = false;
      this.$emit("refresh");
    },

    required(val) {
      return (
        (val && val.toString().length > 0) || this.$t("validation.required")
      );
    },
    short(val) {
      return (
        (val && val.length > 1) || this.$t("validation.minchar", { chars: 2 })
      );
    },
    isNumber(val) {
      return !isNaN(val) || this.$t("validation.number");
    },
    statusIcon(field) {
      let lockerStatus = null;
      let value = this.locker[field];
      if (field == "faultCount") {
        lockerStatus = LockerStatus["faultCount_" + (value !== 0).toString()];
      } else if (field == "isFaulty") {
        lockerStatus =
          this.locker.isActive == true
            ? LockerStatus["isFaulty_" + value.toString()]
            : LockerStatus["isActive_false"];
      } else if (field == "lockerStatus") {
        lockerStatus =
          this.locker.isActive == true
            ? LockerStatus["lockerStatus_" + (value == 0 ? "ok" : "ko")]
            : LockerStatus["isActive_false"];
      } else {
        lockerStatus = LockerStatus[field + "_" + value.toString()];
      }
      return lockerStatus ? lockerStatus.icon : "question_mark";
    },
    statusColor(field) {
      let lockerStatus = null;
      let value = this.locker[field];
      if (field == "isEmpty") {
        let mismatch = (this.locker.article === null) === !value;
        lockerStatus = LockerStatus["isEmpty_" + mismatch.toString()];
      } else if (field == "faultCount") {
        lockerStatus = LockerStatus["faultCount_" + (value !== 0).toString()];
      } else if (field == "isFaulty") {
        lockerStatus =
          this.locker.isActive == true
            ? LockerStatus["isFaulty_" + value.toString()]
            : LockerStatus["isActive_false"];
      } else if (field == "lockerStatus") {
        lockerStatus =
          this.locker.isActive == true
            ? LockerStatus["lockerStatus_" + (value == 0 ? "ok" : "ko")]
            : LockerStatus["isActive_false"];
      } else {
        lockerStatus = LockerStatus[field + "_" + value.toString()];
      }
      //console.log(value);
      return lockerStatus ? lockerStatus.color : "primary";
    },
    statusValue(status) {
      return LockerLogStatus[status] ? LockerLogStatus[status] : status;
    },
  },
  computed: {
    currency() {
      return this.$store.getters.currency;
    },
  },
};
</script>
