import Api from "./Api";
export default {
  paginate(paginate) {
    return Api().post("/contract/page", paginate);
  },
  confirmSent(id) {
    return Api().get(`/contract/${id}/confirm`);
  },
  contractExport(id) {
    return Api().get(`/contract/${id}/export`, {responseType: 'arraybuffer'});
  }
};
